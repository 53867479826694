import Vue from 'vue'
import Router from 'vue-router'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _966c8d34 = () => interopDefault(import('../pages/aboutus.vue' /* webpackChunkName: "pages/aboutus" */))
const _93ae8ce6 = () => interopDefault(import('../pages/cipp-offer/index.vue' /* webpackChunkName: "pages/cipp-offer/index" */))
const _3e918a54 = () => interopDefault(import('../pages/help.vue' /* webpackChunkName: "pages/help" */))
const _7fde2c5d = () => interopDefault(import('../pages/verify-email.vue' /* webpackChunkName: "pages/verify-email" */))
const _17b2b1cc = () => interopDefault(import('../pages/business/analytics.vue' /* webpackChunkName: "pages/business/analytics" */))
const _a72591c8 = () => interopDefault(import('../pages/business/communication.vue' /* webpackChunkName: "pages/business/communication" */))
const _524a188a = () => interopDefault(import('../pages/business/membersupport.vue' /* webpackChunkName: "pages/business/membersupport" */))
const _12c3d2ff = () => interopDefault(import('../pages/dashboard/combine.vue' /* webpackChunkName: "pages/dashboard/combine" */))
const _a4ea8c10 = () => interopDefault(import('../pages/dashboard/documents.vue' /* webpackChunkName: "pages/dashboard/documents" */))
const _56ece3d7 = () => interopDefault(import('../pages/dashboard/landing.vue' /* webpackChunkName: "pages/dashboard/landing" */))
const _bddafd58 = () => interopDefault(import('../pages/dashboard/nextsteps.vue' /* webpackChunkName: "pages/dashboard/nextsteps" */))
const _3b187e24 = () => interopDefault(import('../pages/dashboard/report.vue' /* webpackChunkName: "pages/dashboard/report" */))
const _2ba2e77f = () => interopDefault(import('../pages/dashboard/reports.vue' /* webpackChunkName: "pages/dashboard/reports" */))
const _57b61b2e = () => interopDefault(import('../pages/dashboard/retirement.vue' /* webpackChunkName: "pages/dashboard/retirement" */))
const _83534426 = () => interopDefault(import('../pages/dashboard/review-choices.vue' /* webpackChunkName: "pages/dashboard/review-choices" */))
const _c0a60ce8 = () => interopDefault(import('../pages/dashboard/selected-funds.vue' /* webpackChunkName: "pages/dashboard/selected-funds" */))
const _09586fc0 = () => interopDefault(import('../pages/dashboard/selfhelpdirectory.vue' /* webpackChunkName: "pages/dashboard/selfhelpdirectory" */))
const _11c91f93 = () => interopDefault(import('../pages/dashboard/settings.vue' /* webpackChunkName: "pages/dashboard/settings" */))
const _2970a59b = () => interopDefault(import('../pages/dashboard/shortlist-funds.vue' /* webpackChunkName: "pages/dashboard/shortlist-funds" */))
const _00c89a2c = () => interopDefault(import('../pages/onboarding/annuity-options.vue' /* webpackChunkName: "pages/onboarding/annuity-options" */))
const _6abbd89a = () => interopDefault(import('../pages/onboarding/authorization.vue' /* webpackChunkName: "pages/onboarding/authorization" */))
const _6269e135 = () => interopDefault(import('../pages/onboarding/checkout.vue' /* webpackChunkName: "pages/onboarding/checkout" */))
const _05c564d0 = () => interopDefault(import('../pages/onboarding/employment-info.vue' /* webpackChunkName: "pages/onboarding/employment-info" */))
const _069fbcf0 = () => interopDefault(import('../pages/onboarding/final-salary.vue' /* webpackChunkName: "pages/onboarding/final-salary" */))
const _8c6f279a = () => interopDefault(import('../pages/onboarding/fund-selection.vue' /* webpackChunkName: "pages/onboarding/fund-selection" */))
const _1582088a = () => interopDefault(import('../pages/onboarding/login.vue' /* webpackChunkName: "pages/onboarding/login" */))
const _de1088f6 = () => interopDefault(import('../pages/onboarding/login-error.vue' /* webpackChunkName: "pages/onboarding/login-error" */))
const _00bd1e80 = () => interopDefault(import('../pages/onboarding/other-pot-addition.vue' /* webpackChunkName: "pages/onboarding/other-pot-addition" */))
const _53b567a4 = () => interopDefault(import('../pages/onboarding/pension-details.vue' /* webpackChunkName: "pages/onboarding/pension-details" */))
const _a334041a = () => interopDefault(import('../pages/onboarding/pension-provider-by-name.vue' /* webpackChunkName: "pages/onboarding/pension-provider-by-name" */))
const _4f109f3c = () => interopDefault(import('../pages/onboarding/personal-info.vue' /* webpackChunkName: "pages/onboarding/personal-info" */))
const _2faa92ac = () => interopDefault(import('../pages/onboarding/redeem-voucher.vue' /* webpackChunkName: "pages/onboarding/redeem-voucher" */))
const _1d191e88 = () => interopDefault(import('../pages/onboarding/reset-password.vue' /* webpackChunkName: "pages/onboarding/reset-password" */))
const _621edde4 = () => interopDefault(import('../pages/onboarding/success.vue' /* webpackChunkName: "pages/onboarding/success" */))
const _4b0b1945 = () => interopDefault(import('../pages/onboarding/suez-info.vue' /* webpackChunkName: "pages/onboarding/suez-info" */))
const _564b6ef3 = () => interopDefault(import('../pages/personal/account.vue' /* webpackChunkName: "pages/personal/account" */))
const _9203644c = () => interopDefault(import('../pages/personal/nextsteps.vue' /* webpackChunkName: "pages/personal/nextsteps" */))
const _7bb2b6a3 = () => interopDefault(import('../pages/personal/retirement.vue' /* webpackChunkName: "pages/personal/retirement" */))
const _76477a9c = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _894bdee6 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: decodeURI('/'),
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/aboutus",
    component: _966c8d34,
    name: "aboutus"
  }, {
    path: "/cipp-offer",
    component: _93ae8ce6,
    name: "cipp-offer"
  }, {
    path: "/help",
    component: _3e918a54,
    name: "help"
  }, {
    path: "/verify-email",
    component: _7fde2c5d,
    name: "verify-email"
  }, {
    path: "/business/analytics",
    component: _17b2b1cc,
    name: "business-analytics"
  }, {
    path: "/business/communication",
    component: _a72591c8,
    name: "business-communication"
  }, {
    path: "/business/membersupport",
    component: _524a188a,
    name: "business-membersupport"
  }, {
    path: "/dashboard/combine",
    component: _12c3d2ff,
    name: "dashboard-combine"
  }, {
    path: "/dashboard/documents",
    component: _a4ea8c10,
    name: "dashboard-documents"
  }, {
    path: "/dashboard/landing",
    component: _56ece3d7,
    name: "dashboard-landing"
  }, {
    path: "/dashboard/nextsteps",
    component: _bddafd58,
    name: "dashboard-nextsteps"
  }, {
    path: "/dashboard/report",
    component: _3b187e24,
    name: "dashboard-report"
  }, {
    path: "/dashboard/reports",
    component: _2ba2e77f,
    name: "dashboard-reports"
  }, {
    path: "/dashboard/retirement",
    component: _57b61b2e,
    name: "dashboard-retirement"
  }, {
    path: "/dashboard/review-choices",
    component: _83534426,
    name: "dashboard-review-choices"
  }, {
    path: "/dashboard/selected-funds",
    component: _c0a60ce8,
    name: "dashboard-selected-funds"
  }, {
    path: "/dashboard/selfhelpdirectory",
    component: _09586fc0,
    name: "dashboard-selfhelpdirectory"
  }, {
    path: "/dashboard/settings",
    component: _11c91f93,
    name: "dashboard-settings"
  }, {
    path: "/dashboard/shortlist-funds",
    component: _2970a59b,
    name: "dashboard-shortlist-funds"
  }, {
    path: "/onboarding/annuity-options",
    component: _00c89a2c,
    name: "onboarding-annuity-options"
  }, {
    path: "/onboarding/authorization",
    component: _6abbd89a,
    name: "onboarding-authorization"
  }, {
    path: "/onboarding/checkout",
    component: _6269e135,
    name: "onboarding-checkout"
  }, {
    path: "/onboarding/employment-info",
    component: _05c564d0,
    name: "onboarding-employment-info"
  }, {
    path: "/onboarding/final-salary",
    component: _069fbcf0,
    name: "onboarding-final-salary"
  }, {
    path: "/onboarding/fund-selection",
    component: _8c6f279a,
    name: "onboarding-fund-selection"
  }, {
    path: "/onboarding/login",
    component: _1582088a,
    name: "onboarding-login"
  }, {
    path: "/onboarding/login-error",
    component: _de1088f6,
    name: "onboarding-login-error"
  }, {
    path: "/onboarding/other-pot-addition",
    component: _00bd1e80,
    name: "onboarding-other-pot-addition"
  }, {
    path: "/onboarding/pension-details",
    component: _53b567a4,
    name: "onboarding-pension-details"
  }, {
    path: "/onboarding/pension-provider-by-name",
    component: _a334041a,
    name: "onboarding-pension-provider-by-name"
  }, {
    path: "/onboarding/personal-info",
    component: _4f109f3c,
    name: "onboarding-personal-info"
  }, {
    path: "/onboarding/redeem-voucher",
    component: _2faa92ac,
    name: "onboarding-redeem-voucher"
  }, {
    path: "/onboarding/reset-password",
    component: _1d191e88,
    name: "onboarding-reset-password"
  }, {
    path: "/onboarding/success",
    component: _621edde4,
    name: "onboarding-success"
  }, {
    path: "/onboarding/suez-info",
    component: _4b0b1945,
    name: "onboarding-suez-info"
  }, {
    path: "/personal/account",
    component: _564b6ef3,
    name: "personal-account"
  }, {
    path: "/personal/nextsteps",
    component: _9203644c,
    name: "personal-nextsteps"
  }, {
    path: "/personal/retirement",
    component: _7bb2b6a3,
    name: "personal-retirement"
  }, {
    path: "/blog/:slug?",
    component: _76477a9c,
    name: "blog-slug"
  }, {
    path: "/",
    component: _894bdee6,
    name: "index"
  }],

  fallback: false
}

export function createRouter () {
  return new Router(routerOptions)
}
